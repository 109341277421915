body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#socialmediamenu-root {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  pointer-events: none;
}

@font-face {
  font-family: 'Century Gothic';
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('Century Gothic'), url('/fonts/CenturyGothic.ttf') format('truetype');
}

@font-face {
  font-family: 'Century Gothic';
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('Century Gothic Bold'), url('/fonts/centuryGothicBold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Open Sans';
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('Open Sans'), url('/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Kosugi Maru';
  font-display: swap;
  unicode-range: U+000-5FF, U+3000-9FAF;
  src: local('Kosugi Maru'), url('/fonts/KosugiMaru-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Dank Mono';
  font-display: swap;
  unicode-range: U+000-5FF;
  src: local('Dank Mono'), url('/fonts/DankMono-Italic.ttf') format('truetype');
}
